.modal-wrapper{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.5);  
    display: flex;
    align-items: center;
    justify-content: center;
    animation: modal-bg-show .5s;
}

.login-modal{
    width: 90%;
    max-width: 400px;
    margin:0 auto;
    border-radius: .3rem;
    background-color: #fff;
    animation: modal-show .5s;
    overflow: hidden;
}

.login-input{
    padding-top:10px;
}

.login-header{
    position: relative;
    padding: 16px 64px 16px 16px;
    background-color: #f1f1f1;
    font-weight: 700;
}

.login-id{
    padding:10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-password{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.email{
    padding-top:5px;
    padding-bottom:5px;
    padding-left: 15px;
    padding-right: 15px;
    width: 290px;
    height: 30px;
    font-size: 14px;
    border: 1px solid rgb(222, 226, 230);
}

.password{
    padding-top:5px;
    padding-bottom:5px;
    padding-left: 15px;
    padding-right: 15px;
    width: 290px;
    height: 30px;
    font-size: 14px;
    border: 1px solid rgb(222, 226, 230);
}


.close-login{
    border-top: 1px solid;
    padding: 12px 16px;
    text-align: right;
}

.close-button{
    padding: 6px 12px;
    color: #fff;
    background-color: #6c757d;
    border-radius: 5px;
    font-size: 13px;
}


@keyframes modal-show {
    from {
        opacity: 0;
        margin-top: -50px;
    }
    to {
        opacity: 1;
        margin-top: 0;
    }
}
@keyframes modal-bg-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}