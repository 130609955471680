.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.fs-postion {
  background-position : 'top !important'
}

.tblContent {
	width: 100%;
	border-collapse: collapse;
	margin-top: 10px; /* Adjust the margin as needed */
  }
  
  .tblContent tbody,
  .tblContent tfoot,
  .tblContent tr {
	border: 1px solid #ddd;
  }
  
  .tblContent td {
	border: 1px solid #ddd;
	padding: 8px;
  }
  

  .tblContent td {
	border: 1px solid #ddd;
	padding: 8px;
  }
  
  .tblContent .line1 {
	font-weight: bold;
	background-color: #f2f2f2; /* Add a background color as needed */
  }

  .outer-container {
    border: 2px solid #3366cc; /* Change the color as needed */
    background-color: #cce5ff; /* Change the color as needed */
    padding: 10px; /* Adjust padding as needed */
  }

  .inner-content {
    background-color: #f5f5f5; /* This is the color you provided */
    padding: 15px; /* Adjust padding as needed */
  }


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: 'JalnanGothic';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_231029@1.1/JalnanGothic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GangwonEdu_OTFBoldA';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2201-2@1.0/GangwonEdu_OTFBoldA.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SEBANG_Gothic_Bold';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2104@1.0/SEBANG_Gothic_Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GmarketSansMedium';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

input:focus{
	bordr-color:#fff;
}

